import React from "react"
import { Helmet } from "react-helmet"
import { CONTENT } from "../contants"

export const SeoHead = ({ title = null }) => (
  <Helmet>
    <meta charSet="utf-8" />
    {<title>{title || `${CONTENT.title} "Stop Armenian Terror!"`}</title>}
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="description" content={CONTENT.desc} />
    <meta name="keywords" content={CONTENT.keywords} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={CONTENT.url} />
    <meta
      property="og:image"
      content={
        CONTENT.id === "ganja"
          ? require("../../static/preview.png")
          : require("../../static/barda_preview.png")
      }
    />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={CONTENT.url} />
    <meta property="twitter:title" content={CONTENT.title} />
    <meta property="twitter:description" content={CONTENT.desc} />
    <meta
      property="twitter:image"
      content={
        CONTENT.url +
        `${
          CONTENT.id === "ganja"
            ? require("../../static/preview.png")
            : require("../../static/barda_preview.png")
        }`
      }
    />
  </Helmet>
)
