import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import { Copy } from "./Copy"
import { CONTENT } from "../contants"

export const Heading = ({ isPrimary = false }) => {
  return (
    <Wrapper isPrimary={isPrimary}>
      <Container>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 2 }}
        >
          <Copy display="h2" type="h2" color="white" bold center letterSpace>
            PRAY FOR
          </Copy>
        </motion.div>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Copy display="display" type="h1" color="accent" center>
            {CONTENT.name}
          </Copy>
        </motion.div>
        {isPrimary && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <Copy
              display="detail"
              type="p"
              color="sand"
              center
              style={{ margin: "24px 0" }}
            >
              {CONTENT.desc}
            </Copy>
          </motion.div>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 100px auto;
  position: relative;
  background-color: transparent;
  max-width: 600px;

  ${props => props.theme.isMobile} {
    ${props =>
      props.isPrimary &&
      `
        margin: auto;
        margin-bottom: 0;
        padding: 100px 0;
    `}
  }
`

const Container = styled.div`
  margin: auto;
`
