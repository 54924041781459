import React from "react"
import styled, { css } from "styled-components"

export const Copy = ({
  display,
  type,
  color,
  bold,
  children,
  nowrap,
  center,
  letterSpace,
  ...rest
}) => {
  return (
    <InitialCopy
      display={display}
      type={type}
      color={color}
      bold={bold}
      nowrap={nowrap}
      center={center}
      letterSpace={letterSpace}
      {...rest}
    >
      {children}
    </InitialCopy>
  )
}

const InitialCopy = styled(({ type, bold, ...props }) => {
  delete props?.nowrap
  return React.createElement(type || "span", props)
})`
  font-family: ${props => props.theme.fontFamily};

  ${props => props.display === "display" && display};
  ${props => props.display === "h1" && h1}
  ${props => props.display === "h2" && h2}
  ${props => props.display === "h3" && h3}
  ${props => props.display === "body" && body}
  ${props => props.display === "detail" && detail}
  ${props => props.display === "label" && label}
  ${props => props.display === "code" && code}
  ${props => props.center && center}
  
  color: ${props => props.theme.colors[props.color || "white"]};
  text-decoration: none;
  ${props => props.bold && bold}
  ${props => props.nowrap && nowrap}
  ${props => props.letterSpace && letterSpace}
`

const center = css`
  text-align: center;
`

const letterSpace = css`
  letter-spacing: 1em;
`

const bold = css`
  font-weight: bold;
`

const nowrap = css`
  white-space: nowrap;
`

const display = css`
  font-weight: 900;
  font-size: 87px;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: -0.03em;

  ${props => props.theme.isMobile} {
    line-height: 0.8;
    font-size: 50px;
  }
`

const h1 = css`
  font-weight: 900;
  font-size: 40px;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: -0.03em;
  line-height: 0.8;

  ${props => props.theme.isMobile} {
    font-size: 34px;
  }
`

const h2 = css`
  font-weight: 500;
  font-size: 26px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;

  ${props => props.theme.isMobile} {
    font-size: 20px;
  }
`

export const h3 = css`
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`

const body = css`
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.04em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`

const detail = css`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.28em;
  line-height: 16px;
  text-transform: uppercase;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;

  ${props => props.theme.isMobile} {
    font-size: 10px;
    line-height: 12px;
  }
`

export const label = css`
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`

export const code = css`
  font-size: 12px;
  font-family: "HelveticaNowText", sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.02em;
`
