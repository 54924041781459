import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { CONTENT } from "../contants"
import { Copy } from "./Copy"

export const Nav = props => {
  return (
    <NavWrapper>
      <ListItem>
        <Link to="/">
          <Copy display="detail" color="white">
            Home
          </Copy>
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/about">
          <Copy display="detail" color="white">
            About us
          </Copy>
        </Link>
      </ListItem>
      <ListItem>
        <a
          href={`https://twitter.com/search?q=${encodeURIComponent(
            CONTENT.hashtag
          )}`}
          target="_blank"
        >
          <Copy display="detail" color="white">
            {CONTENT.hashtag}
          </Copy>
        </a>
      </ListItem>
    </NavWrapper>
  )
}

const NavWrapper = styled.nav`
  position: absolute;
  z-index: 999;
  top: 24px;
  right: 24px;

  ${props => props.theme.isMobile} {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    left: 0;
    right: initial;
    justify-content: center;
  }
`

const ListWrapper = styled.ul`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

const ListItem = styled.li`
  opacity: 0.3;
  transition: all 0.2s ease-in;
  cursor: pointer;
  text-align: right;

  &:hover {
    opacity: 1;
  }

  ${props => props.theme.isMobile} {
    padding: 0 10px;
    display: inline-block;
  }
`
