const GANJA = {
  id: "ganja",
  title: "Pray for Ganja",
  name: "GANJA CITY",
  killed: 25,
  wounded: 95,
  desc: `Ganja, Azerbaijan's second-largest city which is far from any military targets and the conflict zone was shelled by Armenian mid-range ballistic missiles during the night on the 11th and 17th of October. The first SCUD missile attack hit densely populated civilian areas and claimed the lives of many, including children. These missile attacks were launched at innocent civilians after the recent clashes that erupted between Azerbaijan and Armenia on September the 27th over the occupied Nagorno-Karabakh region and its adjacent territories.`,
  hashtag: "#PrayForGanja",
  fundsRaised: 13653,
  completed: true,
  url: "https://prayforganja.com",
  keywords: "ganja, azerbaijan, terror, armenia, stopterror, gence, gəncə",
}

const BARDA = {
  id: "barda",
  title: "Pray for Barda",
  name: "BARDA CITY",
  killed: 26,
  wounded: 73,
  desc: `Barda, the ancient city of Azerbaijan with historic sites of Caucasian Albanian times, far from military targets and conflict zone was shelled by Armenian "Smerch" missiles on October 27th and October 28th. The initial attack has claimed lives of 5 civilians, 1 being a 7 y.o kid, and critically wounded of 13 more and the next one killed 21 civilians, with another 60 more getting injured.`,
  hashtag: "#PrayForBarda",
  fundsRaised: null,
  completed: false,
  url: "https://prayforbarda.com",
  keywords: "barda, azerbaijan, terror, armenia, stopterror, berde, bərdə",
}

export const CONTENT = {
  GANJA,
  BARDA,
  // @ts-ignore
}[process.env.GATSBY_CITY]
