export const theme = {
  colors: {
    accent: "#A60000",
    darkAccent: "#6b0707",
    secondary: "black",
    white: "white",
    grey: "#595962",
    grey2: "#1e1e24",
    green: "#4caf50",
    sand: "#CDCEAE",
  },
  isMobile: "@media (max-width: 600px)",
  fonts: {
    defaultFamily:
      '"HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  },
}
